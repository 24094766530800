// import React from 'react'
// import AsyncStorage from '@react-native-community/async-storage';

/**
 * Retorna todas as palavras
 */
export function allWords() {
    var _words = [
        'Santo','Igreja','Marido','Esposa','Sogra','Camelo','Deserto','Pecado','Chuva','Violão','Ministro','Guitarra','Baixo','Bateria','Teclado','Célula',
        'Sacrifício','Tribulação','Peixe','Pescador','Jumenta','Balaão','Túmulo','Figueira','Oração','Getsêmani','Louvor','Manjedoura','Heródes','Barrabás',
        'Daniel','Nabucodonosor','Fornalha','Formiga','Pragas','Maná','Saul','Jejum','Sodoma','Elias','Fogo','Água','Pão','Gigante','Golias','Cóva','Azeite','Águia',
        'Éden','Caim','Abel','Malaquias','Bispo','Apóstolo','Neemias','Israel','Belém','Paulo','Saulo','Jerusalem','Apocalipse','Diabo','Inimigo','Abraão',
        'Jacó','Vinho','Videira','Páscoa','Dinheiro','Beteseba','Lepra','Rei','Juiz','Egito','Batizar','Mergulhar','Jonas','Bíblia','Anjo','Viúva','Orar','Arca',
        'Vermelho','Vaidade','Adultério','Mar','Igreja','Templo','Esmola','Azul','Verde','Amarelo','Preto','Branco','Janeiro','Fevereiro','Março','Abril','Maio',
        'Jesus','Cruz','Judas','Davi','Salomão','Sansão','Pedro','Maçã','Serpente','Adão','Eva','Moisés','Noé','Jó','Pastor','Ovelha','Idolatrar','Mandamentos',
        'Junho','Julho','Agosto','Setembro','Outubro','Novembro','Dezembro','Natal','Cantata','Aliança','Noivar','Casar','Salmos','Rute','João','Maria',
        'Zaqueu','Mateus','Lucas','Marcos','Coríntios','Romanos','Timóteo','Sinai','Oliveira','Centurião','Lázaro','Jericó','Judá','Leão','Nínive','José',
        'Amar','Odiar','Vaso','Oleiro','Inferno','Paraíso','Devocional','Pregação','Oferta','Dízimo','Paralítico','Coxo','Diácono','Ceia','Sinédrio','Prisão',
    ]
    return _words
}

/**
 * Pega uma palavra aleatória
 */
export function getRandWord() {
    //Pega todas as palavras
    var _allWords = allWords()
    //seleciona uma aleatoriamente
    var _randWord = _allWords[Math.floor(Math.random() * _allWords.length)];
    return _randWord
}

/**
 * Pega uma quantidade especifica de palavras aleatórias
 */
export function getNewWords(numWords) {
    //prepara o array
    var _selectedsWords = [];
    //percorre todas as palavras
    var i = 0;
    while (i < numWords) {
        //seleciona uma aleatoriamente
        var _randWord = getRandWord()
        //se já existe no array, seleciona novamente
        if (!_selectedsWords.includes(_randWord)) {
            //guarda no array
            _selectedsWords.push(_randWord)
            i++
        }
        
    }
    console.log(_selectedsWords)
    return _selectedsWords
}