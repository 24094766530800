import React from 'react';
// import { BrowserRouter, Switch, Route } from 'react-router-dom';
import './App.css';
import { getNewWords } from './words';
import startAudio from './assets/audio/start.mp3'
import errouAudio from './assets/audio/errou.mp3'
import acertouAudio from './assets/audio/acertou.mp3'
import pulouAudio from './assets/audio/pulou.mp3'
import acabouAudio from './assets/audio/acabou.mp3'
import proibidoAudio from './assets/audio/proibido.mp3'
import ticAudio from './assets/audio/tic.mp3'
import finishedAudio from './assets/audio/finished.mp3'

const _initialState = {
  started: false,
  words: [],
  currentWord: '',
  time: 90, //Segundos
  totalWords: 5,
  pulos: 3,
  acertos: 0,
  erros: 0
}

class App extends React.Component {

  state = _initialState

  _newGame = (e) => {
    e.preventDefault()
    //seleciona 5 palavras 
    var _words = getNewWords(this.state.totalWords)
    this.setState({
      ..._initialState,
      words: _words,
    })
  }

  _initCounter = () => {
    this.counter = setInterval(this._countTimer, 1000)
  }

  _stopCounter = () => {
    clearInterval(this.counter);
  }

  _countTimer = () => {
    //se chegou ao fim
    if (this.state.time === 0) {
      //Toca o som
      let audio = new Audio(acabouAudio)
      audio.play()
      //Para o contador
      this._stopCounter();
      //mostra o placar
      this.setState({
        words: []
      })
    } else {
      this.setState({
        time: this.state.time - 1
      })
      //Toca o som
      let audio = new Audio(ticAudio)
      audio.play()
    }
  }

  _start = () => {
    this.setState({
      started: true,

    }, this._initCounter())
    this._nextWord()
    //Toca o som
    let audio = new Audio(startAudio)
    audio.play()
  }

  _errou = () => {
    //Toca o som
    let audio = new Audio(errouAudio)
    audio.play()

    //marca que errou
    this.setState({
      erros: this.state.erros + 1
    })
    //remove a palavra
    this.state.words.splice(0, 1)
    //Busca a próxima palavra
    this._nextWord();
  }

  _acertou = () => {
    //Toca o som
    let audio = new Audio(acertouAudio)
    audio.play()

    //marca que acertou
    this.setState({
      acertos: this.state.acertos + 1
    })
    //remove a palavra
    this.state.words.splice(0, 1)
    //Busca a próxima palavra
    this._nextWord();

  }

  _pulou = () => {
    //Se ainda pode pular e tem mais de uma palavras
    if (this.state.pulos > 0 && this.state.words.length > 1) {
      //toca o som
      let audio = new Audio(pulouAudio)
      audio.play()

      //remove a palavra
      this.state.words.splice(0, 1)

      //coloca no final da fila
      this.state.words.push(this.state.currentWord)

      //Marca quantos pulos
      this.setState({
        pulos: this.state.pulos - 1,
      })
      //Busca a próxima palavra
      this._nextWord();
    } else {
      //toca o som
      let audio = new Audio(proibidoAudio)
      audio.play()
    }
  }

  _nextWord = () => {
    //se ainda tem palavras
    if (this.state.words.length > 0) {
      //Se não, volta pra primeira
      this.setState({
        currentWord: this.state.words[0]
      })
    } else {
      //Toca o som
      let audio = new Audio(finishedAudio)
      audio.play()
      //Para o contador
      this._stopCounter();
    }
  }

  render() {

    return (
      <div className="App">
        <header className="App-header">

          {this.state.started &&
            <div>
              {this.state.words.length > 0 &&
                <div>
                  <div className="timer">{this.state.time}</div>
                  <div className="palavra">{this.state.currentWord}</div>
                </div>
              }
              <div className="placar">
                <div className="placar-itens pulos">PULOS RESTANTES: {this.state.pulos}</div>
                <div className="placar-itens erros">ERROS: {this.state.erros}</div>
                <div className="placar-itens acertos">ACERTOS: {this.state.acertos}</div>
              </div>

            </div>
          }

          <div className="botoes">

            {this.state.words.length === 0 &&
              <div>
                <div className="div-config">
                  <label>Quantidade de Palavras: </label><br />
                  <input
                    className="text-input"
                    placeholder="5"
                    onChange={e => this.setState({ totalWords: e.target.value })}
                    type="number"
                  />
                </div>

                <button onClick={e => this._newGame(e)}>Novo Jogo</button>
              </div>
            }

            {!this.state.started && this.state.words.length > 0 &&
              <div>
                <button onClick={e => this._start(e)}>Iniciar</button>
              </div>
            }

            {this.state.started && this.state.words.length > 0 &&
              <div className="buttons">
                <button className="button pulou" onClick={e => this._pulou(e)}>Pulou</button>
                <button className="button errou" onClick={e => this._errou(e)}>Errou</button>
                <button className="button acertou" onClick={e => this._acertou(e)}>Acertou</button>
              </div>
            }
          </div>
        </header>
      </div>
    );
  }
}

export default App;
